import axios from "axios";
import React from 'react';
import config from '../../config';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const InvestmentCalculations = () => {
    const companyId = useSelector((state) => state.user.companyId);
    const name = useSelector((state) => state.user.name);
    const navigate = useNavigate();
    const [isInsertOrder, setIsInsertOrder] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [isOrderUpdateStatusAllowed, setIsOrderUpdateStatusAllowed] = useState(false);
    const [err, setErr] = useState("")
    const [baskets, setBaskets] = useState([])
    const [inputs, setInputs] = useState({
        currentValuation: 0,
        cashBalance: 0,
        investment: 0,
        basketId: 0
    })

    useEffect(() => {
        if (name === null || name === "") {
            navigate("/")
        }
    })
    useEffect(() => {
        getBaskets();

    }, [])

    const getBaskets = async (e) => {
        try {
            const res = await axios.get(config.BL_API_URL + "/basket/v1/getBasketByCompanyId", { params: { companyId } });

            if (res.data)
                setBaskets(res.data)
            else
                setErr("backend error")

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }
    const handleChange = (e) => {
        setInputs(prev => ({ ...prev, [e.target.name]: Number(e.target.value) }))
    }

    const handleCalculate = (clientCode, basketId) => {
        const width = 400;
        const height = 400;
        const left = (window.screen.width - width) / 2;
        const top = (window.screen.height - height) / 2;
        const axiosConfig = {
            responseType: 'blob',
            headers: {
                Accept: 'Application/csv'
            }
        }
        setErr("");
        if(inputs.basketId <= 0){
            setErr("Select basket")
            return;
        }
        const url = `${config.BL_API_URL}/csvReport/v1/getAllocationCalculations?basketId=${encodeURIComponent(inputs.basketId)}&currentValuation=${encodeURIComponent(inputs.currentValuation)}&cashBalance=${encodeURIComponent(inputs.cashBalance)}&investment=${encodeURIComponent(inputs.investment)}`;

        axios.get(url, axiosConfig).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'allocationCalculations.csv');
            document.body.appendChild(link);
            link.click();
        }).catch((error) => {
            setErr(error)
        }
        );
    }



    return (
        <div className=" my-0 md:px-20 max-w-screen-sm mx-auto h-50 ">
            <div className="py-14 px-1 flex justify-center items-center">
                <span className="text-gray-500 text-xl font-bold">Allocation Calculator</span>
            </div>
            <div className="grid grid-cols-2 gap-2">
                {/* <div className="col-span-2 text-center text-xs "><p>This is just a calculator.</p></div> */}
                <div className="font-bold text-left">Parameters</div>
                <div className="font-bold">Values</div>
                <div className="text-left">Basket Name</div>
                <div>
                    <select
                        onChange={handleChange}
                        name="basketId" id="basketId"
                        className="py-2 px-1 pe-0 block w-full  border-black-200 shadow-sm -mt-px -ms-px rounded-lg text-sm  relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none ">
                        <option value="-1">-Select basket name-</option>
                        {baskets && (
                            baskets.map((basket, index) => <option key={index} value={basket.id}>{basket.name}</option>)
                        )}
                    </select>

                </div>
                 {/*<div className="text-left">Current Valuation</div>
                <div>
                    <input disabled={true}
                        onChange={handleChange}
                        type="number" name="currentValuation" id="currentValuation" placeholder="leave blank for new client"
                        className="py-2 px-3 pe-2 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none "
                    />
                </div>
                <div className="text-left">Cash Balance</div>
                <div>
                    <input disabled={true}
                        onChange={handleChange}
                        type="number" name="cashBalance" id="cashBalance" placeholder="leave blank for new client"
                        className="py-2 px-3 pe-2 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none "
                    />
                </div> */}
                <div className="text-left">New Investment</div>
                <div>
                    <input
                        onChange={handleChange}
                        type="number" name="investment" id="investment" placeholder="0"
                        className="py-2 px-3 pe-2 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none "
                    />
                </div>
                <div className="text-center col-span-2 w-full">
                    <button type="button" onClick={e => handleCalculate()} className="py-2 px-4 inline-flex right-0 gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-btnSecondary text-white hover:bg-btnPrimary disabled:opacity-50 disabled:pointer-events-none " data-hs-overlay="#hs-basic-modal">Download Allocation</button>
                </div>
            </div>
            <div className="grid grid-cols-2 gap-2">
                <div className="col-span-2 text-center text-xs">
                    <p className="text-red-400 mt-8">
                        {err}
                    </p>
                </div>
            </div>
        </div>
    )
}
export default InvestmentCalculations