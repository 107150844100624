import "./App.css";
import About from "./pages/About";
import AddBasket from "./components/Dashboard/AddBasket";
import Contact from "./pages/Contact";
import Dashboard from "./pages/Dashboard";
import EditBasket from "./components/Dashboard/EditBasket";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import MyAccount from "./pages/MyAccount";
import Navbar from "./components/Navbar";
import Preferences from "./pages/Preferences";
import Profile from "./pages/Profile";
import { useDispatch, useSelector } from "react-redux";
import { update } from "./redux/userSlice";
import Reports from "./pages/Reports";
import BasketSubscriberReport from "./pages/BasketSubscriberReport";
import StockEdit from "./pages/StockEdit";
import PendingActions from "./pages/PendingActions";
import Traded from "./pages/Traded";
import InternalMessageLog from "./pages/InternalMessageLog";
import CashRegister from "./components/CashRegister/CashRegisterList";
import AddCashTransaction from "./components/CashRegister/AddCashTransaction";
import TransactionEdit from "./components/TransactionEdit/TransactionEdit";
import ImportTrades from "./components/TransactionEdit/ImportTrades";
import BasketStockPerformance from "./components/Reports/BasketStocksPerformance";
import BasketSubscription from "./components/BasketSubscription/BasketSubscription";
import ClientOrders from "./components/Orders/ClientOrders";
import ClientList from "./components/ClientRegistration/ClientList";
import AddNewClient from "./components/ClientRegistration/AddNewClient";
import GenerateClientOrders from "./components/Orders/GenerateClientOrders";
import ClientHoldingImport from "./components/ClientImport/ClientImport";
import CorporateActions from "./components/CorporateActions/CorporateActions";
import AddCorporateAction from "./components/CorporateActions/addCorporateAction";
import ControlPanel from "./components/AdminPanels/ControlPanel";
import EodProcess from "./components/EODProcess/EodProcess";
import InvestmentCalculations from "./components/Calculations/InvestmentCalculations";
import OrderManagement from './components/Orders/OrderManagement'
import TradeDetails from "./components/TradeConfirmationDetails";
import AsOnHoldingReport from "./components/AsOnHoldingReport/AsOnHoldingReport";
import AllocationHistoryReport from "./components/AllocationHistory/AllocationHistoryReport";
import IndexDataImport from "./components/IndexData/IndexDataImport";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet
} from "react-router-dom";
import Screens from "./components/Admin/Screens";
import ScreenActions from "./components/Admin/ScreenActions";
import AddScreen from "./components/Admin/AddScreen";
import Roles from "./components/Admin/Roles";
import AddRole from "./components/Admin/AddRole";
import RoleMenuMapping from "./components/Admin/RoleMenuMapping";
import UserRoleMapping from "./components/Admin/UserRoleMapping";
import AddScreenActions from "./components/Admin/AddScreenActions";
import MenuScreenActionMapping from "./components/Admin/MenuScreenActionMapping";
import ScreenActionRoleMapping from "./components/Admin/ScreenActionRoleMapping";
import CashLedgerReport from "./components/CashRegister/CashLedgerReport"
import SystemChargesReport from "./components/SystemChargesReport";

function App() {

  const Layout = () => {
    return (
      <div className="app">
        <Navbar />
        <Outlet />
        <Footer />
      </div>
    )
  }
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout/>,
      children:[
        {
          path: "/",
          element: <Home/>
        },
        {
          path: "/about",
          element: <About/>
        },
        {
          path: "/contact",
          element: <Contact/>
        },
        {
          path: "/profile",
          element: <Profile/>
        },
        {
          path: "/preferences",
          element: <Preferences/>
        },
        {
          path: "/myaccount",
          element: <MyAccount/>
        },
        {
          path: "/dashboard",
          element: <Dashboard/>
        },
        {
          path: "/addbasket",
          element: <AddBasket/>
        },
        {
          path: "/EditBasket/:id",
          element: <EditBasket/>
        },
        {
          path: "/BasketReport",
          element: <Reports/>
        },
        {
          path: "/BasketSubscriberReport",
          element: <BasketSubscriberReport />
        },
        {
          path: "/stockedit",
          element: <StockEdit />
        },
        {
          path: "/PendingActions",
          element: <PendingActions />
        },
        {
          path: "/InternalMessageLog",
          element: <InternalMessageLog />
        },
        {
          path: "/cashregister",
          element: <CashRegister />
        },
        {
          path: "/addcashtransaction",
          element: <AddCashTransaction/>
        },
        {
          path: "/transaction",
          element: <TransactionEdit/>
        },
        {
          path: "/basketperformance",
          element: <BasketStockPerformance/>
        },
        {
          path: "/basketSubscription",
          element: <BasketSubscription/>
        },
        {
          path: "/orders",
          element: <ClientOrders/>
        },
        {
          path: "/clientList",
          element: <ClientList/>
        },
        {
          path: "/addNewClient",
          element: <AddNewClient/>
        },
        {
          path: "/generateClientOrders",
          element: <GenerateClientOrders/>
        },
        {
          path: "/clientHoldingImport",
          element: <ClientHoldingImport/>
        },
        {
          path: "/ImportTrades",
          element: <ImportTrades/>
        }
        ,
        {
          path: "/corporateActions",
          element: <CorporateActions/>
        },
        {
          path: "/addCorporateAction",
          element: <AddCorporateAction/>
        },
        {
          path: "/controlpanel",
          element: <ControlPanel/>
        },
        {
          path: "/eodprocess",
          element: <EodProcess/>
        }   ,
        {
          path: "/allocationCalculator",
          element: <InvestmentCalculations/>
        }  
        ,
        {
          path: "/traded",
          element: <Traded/>
        }    ,
        {
          path: "/OrderManagement",
          element: <OrderManagement/>
        }    
        ,
        {
          path: "/TradeDetails",
          element: <TradeDetails/>
        }    
        ,
        {
          path: "/AsOnHoldingReport",
          element: <AsOnHoldingReport/>
        }   
        ,
        {
          path: "/AllocationHistory",
          element: <AllocationHistoryReport/>
        }   ,
        {
          path: "/ImportIndexData",
          element: <IndexDataImport/>
        }  ,
        {
          path: "/Screens",
          element: <Screens/>
        }
        ,
        {
          path: "/ScreenActions",
          element: <ScreenActions/>
        },
        {
          path: "/AddScreen",
          element: <AddScreen/>
        },
        {
          path: "/Roles",
          element: <Roles/>
        },
        {
          path: "/AddRole",
          element: <AddRole/>
        },
        {
          path: "/RoleMenuMapping",
          element: <RoleMenuMapping/>
        },
        {
          path: "/UserRoleMapping",
          element: <UserRoleMapping/>
        },
        { 
          path: "/AddScreenAction",
          element: <AddScreenActions/>
        },
        {
          path: "/MenuScreenActionMapping",
          element: <MenuScreenActionMapping/>
        },
        {
          path: "/ScreenActionRoleMapping",
          element: <ScreenActionRoleMapping/>
        },
        {
          path: "/CashLedgerReport",
          element: <CashLedgerReport/>
        },
        {
          path: "/SystemChargesReport",
          element: <SystemChargesReport/>
        }
        
      ]
    },
  ]);

  const dispatch = useDispatch();

  const user = JSON.parse(localStorage.getItem("user")) || null

  if (user !== null) {
    dispatch(update({ name: user.firstname, email: user.email, companyId: user.companyId, companyName: user.companyName}));
  }

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;

export function useCompanyId(){
  return useSelector((state) => state.companyId)
}

