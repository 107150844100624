import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import { useSelector } from "react-redux";
import config from '../../config';
import { useNavigate } from "react-router-dom";

const AddNewClient = () => {
    const companyId = useSelector((state) => state.user.companyId);

    const [inputs, setInputs] = useState({
        firstName: "",
        lastName: "",
        dob: "",
        brokerId: "",
        companyId: "",
        clientCode: ""
    });
    const [err, setErr] = useState("");
    const navigate = useNavigate();
    const token = useSelector((state) => state.user.token);
    const [brokers, setBrokers] = useState([]);
    const [companies, setCompanies] = useState([]);

    const getBrokers = async () => {
        try {
            const res = await axios.get(config.BL_API_URL + "/brokers/v1/getBrokers")

            if (res.data)
                setBrokers(res.data)
            else
                setErr("backend error")
        } catch (err) {
            if (err.response)
                setErr(err.response.data);
            else

                setErr("Server error...");
        }
    }
    const getCompanies = async () => {
        try {
            const res = await axios.get(config.BL_API_URL + "/company/v1/getAllCompanies");

            if (res.data) {
                const filteredCompanies = res.data.filter(company => company.id === companyId);
                setCompanies(filteredCompanies);
            }
            //setCompanies(res.data)
            else
                setErr("backend error")
        } catch (err) {
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }
    useEffect(() => {
        getBrokers();
        getCompanies();
        const myElement = document.getElementById("brokerId");
        myElement.value = 2;
        const entityName = document.getElementById("companyId");
        entityName.value = 1;
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        const myElement = document.getElementById("brokerId");
        inputs.brokerId = myElement.value;
        const entityName = document.getElementById("companyId");
        inputs.companyId = entityName.value;
        var errCount = 0;
        if (!inputs.firstName) {
            setErr("First Name is required");
            errCount++;
        }
        if (!inputs.lastName) {
            setErr("Last Name is required");
            errCount++;
        }
        if (!inputs.dob) {
            setErr("Date of Birth is required");
            errCount++;
        }
        if (!inputs.clientCode) {
            setErr("Client Code is required");
            errCount++;
        }
        if (!inputs.brokerId) {
            setErr("Broker Name is required");
            errCount++;
        }
        if (!inputs.companyId) {
            setErr("Entity Name is required");
            errCount++;
        }
        if (errCount > 0) {
            return;
        }
        try {

            const res = await axios.post(config.BL_API_URL + "/AppUser/v1/AddNewClient", inputs);
            if (res.status == 200) {
                navigate("/clientList");
            } else {
                setErr("backend error");
            }
        } catch (err) {
            if (err.response) {
                setErr(err.response.data);
            } else {
                setErr("Server error...");
            }
        }
    }

    const handleChange = (e) => {
        setInputs(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }
    const handleAdd = async (e) => {
        e.preventDefault();
    }

    return (
        <div>
            <div className="my-12 md:px-14 max-w-screen-2xl mx-auto ">
                <div className="max-w-4xl px-4 sm:px-6 lg:px-8 lg:py-2 mx-auto">
                    <div className="bg-formbackground rounded-xl shadow p-4 sm:p-7 ">
                        <div className="mb-8">
                            <h2 className="text-xl font-bold text-gray-800 ">
                                Add New Client
                            </h2>
                        </div>
                        <form>
                            <div className="grid sm:grid-cols-12 gap-2 sm:gap-6">

                                {/* first Name ---- */}
                                <div className="sm:col-span-2">
                                    <label htmlFor="firstName" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                        <span >First Name </span>
                                    </label>
                                </div>
                                <div className="sm:col-span-4">
                                    <div className="sm:flex">
                                        <input
                                            onChange={handleChange}
                                            name="firstName" id="firstName"
                                            className="py-2 px-3 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " ></input>
                                    </div>
                                </div>
                                {/* last Name ---- */}
                                <div className="sm:col-span-2">
                                    <label htmlFor="lastName" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                        <span >Last Name</span>
                                    </label>
                                </div>
                                <div className="sm:col-span-4">
                                    <div className="sm:flex">
                                        <input
                                            onChange={handleChange}
                                            name="lastName" id="lastName"
                                            className="py-2 px-3 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " ></input>
                                    </div>
                                </div>


                                {/* Category ---- */}
                                <div className="sm:col-span-2">
                                    <label htmlFor="category" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                        <span>Broker Name</span>
                                    </label>
                                </div>
                                <div className="sm:col-span-4">
                                    <div className="sm:flex">
                                        <select
                                            disabled={false}
                                            onChange={handleChange}
                                            name="brokerName" id="brokerId"
                                            className="py-2 px-3 pe-9 block min-w-full w-full sm:w-auto border-gray-200 shadow-sm -mt-px -ms-px rounded-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none ">
                                            <option value="-">- Select -</option>
                                            {brokers && (
                                                brokers.map((broker, index) => <option key={index} value={broker.id}>{broker.name}</option>)
                                            )}
                                        </select>
                                    </div>
                                </div>
                                {/* date from ---- */}
                                <div className="sm:col-span-2">
                                    <label htmlFor="dob" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                        <span >Date of Birth</span>
                                    </label>
                                </div>
                                <div className="sm:col-span-4">
                                    <div className="sm:flex">
                                        <input
                                            onChange={handleChange}
                                            type="date" name="dob" id="dob" placeholder=""
                                            className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " />
                                    </div>
                                </div>


                                {/* Entity ---- */}
                                <div className="sm:col-span-2">
                                    <label htmlFor="companyName" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                        <span >Entity Name</span>
                                    </label>
                                </div>
                                <div className="sm:col-span-4 min-w-full">

                                    <select
                                        disabled={false}
                                        onChange={handleChange}
                                        name="companyName" id="companyId"
                                        className="py-2 px-3 pe-9 block min-w-full w-full sm:w-auto border-gray-200 shadow-sm -mt-px -ms-px rounded-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none ">
                                        <option value="-">- Select -</option>
                                        {companies && (
                                            companies.map((company, index) => <option key={index} value={company.id}>{company.name}</option>)
                                        )}
                                    </select>

                                </div>

                                {/* Amount ---- */}
                                <div className="sm:col-span-2">
                                    <label htmlFor="clientCode" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                        <span > Client Code</span>
                                    </label>
                                </div>
                                <div className="sm:col-span-4">
                                    <div className="sm:flex">
                                        <input
                                            onChange={handleChange}
                                            type="text" name="clientCode" id="clientCode" placeholder=""
                                            className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " />
                                    </div>
                                </div>



                            </div>


                            <div className="flex justify-end gap-x-2 mt-2">

                                <Link to="/clientList" type="button" className="py-1 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none ">
                                    Cancel
                                </Link>
                                <button type="button" onClick={handleSubmit} className="py-1 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none ">
                                    Save changes
                                </button>
                            </div>
                            <p className="text-red-400 mt-8">
                                {err}
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AddNewClient;
