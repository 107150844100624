import React from 'react';
import axios from "axios";
import { useEffect, useState } from "react";
import config from "../../config";
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";

const ClientList = () => {
    const companyId = useSelector((state) => state.user.companyId);
    const [clientList, setClientList] = useState([])
    const [err, setErr] = useState("")
    useEffect(() => {
        const getClientList = async () => {
            try {
                //const res = await axios.get(config.BL_API_URL + "/client/v1/getAllClients");
                const res = await axios.get(`${config.BL_API_URL}/client/v1/getAllClients/${companyId}`);
                if (res.data)
                    setClientList(res.data)
                else
                    setErr("backend error")
            } catch (err) {
                if (err.response)
                    setErr(err.response.data);
                else
                    setErr("Server error...");
            }
        }
        getClientList();
    }, [])

    return (
        <div className="my-12 md:px-14 p-4 max-w-screen-2xl mx-auto ">
        <div className="flex flex-col">
            <div className="-m-1.5 overflow-x-auto">

                <div className="border rounded-lg divide-y divide-gray-200 ">
                    <div className='col-span-2'>
                        <div className=" row-span-1">
                            <div className="py-2 px-2 flex justify-center items-center">
                                <span className="text-gray-500 text-xl font-bold">Client List</span>
                            </div>
                            <div className='col-span-1 flex justify-end items-right gap-x-2 px-4 py-1'>
                                <Link to="/addNewClient" type="button" className="py-2 px-4 inline-flex right-0 gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-btnSecondary text-white hover:bg-btnPrimary disabled:opacity-50 disabled:pointer-events-none " data-hs-overlay="#hs-basic-modal">Register new Client</Link>
                            </div>
                        </div>
                    </div>
                    <div className="overflow-hidden">
                        <div className="overflow-hidden">
                            <table className="min-w-full divide-y divide-gray-200  ">
                                <thead className="bg-gray-50 ">
                                    <tr>
                                        <th scope="col" className="px-6 py-1 text-start text-xs font-medium text-gray-500 uppercase">First Name</th>
                                        <th scope="col" className="px-6 py-1 text-start text-xs font-medium text-gray-500 uppercase">Last Name</th>
                                        <th scope="col" className="px-6 py-1 text-start text-xs font-medium text-gray-500 uppercase">Date of Birth</th>
                                        <th scope="col" className="px-6 py-1 text-xs text-start font-medium text-gray-500 uppercase">Client Code</th>
                                        <th scope="col" className="px-6 py-1 text-start text-xs font-medium text-gray-500 uppercase">Broker Name</th>
                                        <th scope="col" className="px-6 py-1 text-start text-xs font-medium text-gray-500 uppercase">Entity Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {clientList &&

                                        clientList.map((client) => (
                                            <tr>
                                                <td className="px-6 py-1 whitespace-nowrap text-sm  text-gray-800 ">{client.firstName}</td>
                                                <td className="px-6 py-1 whitespace-nowrap text-sm  text-gray-800 ">{client.lastName}</td>
                                                <td className="px-6 py-1 whitespace-nowrap text-sm  text-gray-800 ">{client.dob}</td>
                                                <td className="px-6 py-1 whitespace-nowrap text-sm  text-gray-800 ">{client.clientCode}</td>
                                                <td className="px-6 py-1 whitespace-nowrap text-sm  text-gray-800 ">{client.brokerName}</td>
                                                <td className="px-6 py-1 whitespace-nowrap text-sm  text-gray-800 ">{client.entityName}</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}
export default ClientList;