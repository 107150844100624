import React from 'react';
import axios from "axios";
import { useEffect, useState } from "react";
import config from "../../config";
import { BsFiletypeCsv } from "react-icons/bs";
import { useSelector } from "react-redux";
import { set } from 'date-fns';
import { Link } from 'react-router-dom';
import { TbTableExport } from "react-icons/tb";
import loadingImg from "../../assets/loading.gif"
import { BiSolidEditLocation } from 'react-icons/bi';

const OrderManagement = () => {

    const companyId = useSelector((state) => state.user.companyId);
    const [list, setList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [action, setAction] = useState(0);


    useEffect(() => {

        setList([]);
        getOpenBatchIds(companyId);

    }, [])

    const getOpenBatchIds = async (companyId) => {
        try {
            //const res = await axios.get(config.BL_API_URL + "/client/v1/getUnprocessedBatches", { params: { companyId } });
            const res = await axios.get(`${config.BL_API_URL}/client/v1/getUnprocessedBatches/${companyId}`);

            if (res.status === 200) {
                setList(res.data);
            }

        } catch (err) {
            console.log(err)
        }

    }


    const applyAction = async () => {
        console.log("Action " + action);
        
        console.log("Action ..." + action)
        try {
            if (action === 0 || action === undefined) {
                return;
            }

            let actionUrl;
            if (Number(action) === 1) {
                actionUrl = "applyAction"

            } else if (Number(action) === 2) {

                actionUrl = "changeToError"

            } else if (Number(action) === 4) {
                actionUrl = "changeToOpen"

            } else if (Number(action) === 3) {
                actionUrl = "changeToOnHold"

            }
            console.log("Value ... " + actionUrl)
            if (actionUrl !== null && actionUrl !== "" && actionUrl !== undefined) {
                setIsLoading(true);

                const res = await axios.post(config.BL_API_URL + "/client/v1/" + actionUrl, list);

                if (res.status === 200) {
                    getOpenBatchIds(companyId);
                }                
            }
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            console.log(err)
        }
    }

    const onSelectionChange = (e) => {
        if (e.target.value === "-") {
            return;
        }
        setAction(e.target.value);
    }

    const handleCheckAll = (event) => {
        const newItems = list.map(item => ({
            ...item,
            isChecked: event.target.checked,
        }));
        setList(newItems);
        setIsAllChecked(event.target.checked);
    };

    const handleCheck = (id) => {

        const newItems = list.map(item => {
            if (item.batchId === id) {
                return { ...item, isChecked: !item.isChecked };
            }
            return item;
        });
        setList(newItems);

        // Update isAllChecked based on individual checks
        setIsAllChecked(newItems.every(item => item.isChecked));
    };

    return (
        <div>
            <div className="my-14 md:px-14 p-2 max-w-screen-xl mx-auto ">
                <div className="flex flex-col">
                    <div className="-m-1.5 overflow-x-auto">

                        <div className="border rounded-lg divide-y divide-gray-200 ">
                            <div className='col-span-2'>
                                <div className=" col-span-1">
                                    <div className="py-1 px-4 flex justify-center items-center">
                                        <span className="text-gray-500 text-2xl font-bold">Batch Order Management</span>
                                    </div>
                                    <div className='row-span-1 flex justify-center content-center items-center gap-x-2 py-2 px-4'>
                                        <div className='col-span-1'>
                                            <label className='px-6 py-2 text-left font-medium' >Action</label>
                                        </div>
                                        <div className='col-span-1'>
                                            <select onChange={onSelectionChange} id="batchId" name="batchId" defaultValue="-" default className='border border-gray-300 rounded-md' >
                                                <option value="-">Select</option>
                                                <option value="1">Remove</option>
                                                <option value="2">Move to Error</option>
                                                <option value="3">Move to OnHold</option>
                                                <option value="4">Move to Open</option>
                                            </select>
                                        </div>
                                        <div className='col-span-1'>
                                            <button onClick={e => applyAction()}
                                                class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                                                {isLoading &&
                                                    <img src={loadingImg} alt="Icon" className="w-6 text-left justify-start items-right" />
                                                }
                                                {!isLoading &&
                                                    <svg class="fill-current w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><polyline fill="none" points="3.7 14.3 9.6 19 20.3 5" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" /></svg>
                                                }
                                                <span>Apply</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="overflow-hidden">
                                <div className="overflow-hidden">
                                    <table className="min-w-full divide-y divide-gray-200  ">
                                        <thead className="bg-gray-50 ">
                                            <tr>
                                                <th scope="col" className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase">#</th>
                                                <th scope="col" className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase">
                                                    <input
                                                        type="checkbox"
                                                        checked={isAllChecked}
                                                        onChange={handleCheckAll}
                                                    />
                                                    Select</th>
                                                <th scope="col" className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase">Batch Id</th>
                                                <th scope="col" className="px-6 py-2 text-left text-xs font-medium text-gray-500 uppercase">Batch Order Type</th>
                                                <th scope="col" className="px-6 py-2 text-start text-xs font-medium text-gray-500 uppercase">Action</th>
                                                <th scope="col" className="px-6 py-2 text-start text-xs font-medium text-gray-500 uppercase">Client Code</th>
                                                <th scope="col" className="px-6 py-2 text-start text-xs font-medium text-gray-500 uppercase">Message</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list &&
                                                list.map((item, index) => (
                                                    <tr>
                                                        <td className="px-6  whitespace-nowrap text-sm  text-gray-800 ">{index + 1}</td>
                                                        <td className="px-6  whitespace-nowrap text-sm  text-gray-800 ">
                                                            <input type='checkbox' id={item.batchId} checked={item.isChecked}
                                                                onChange={() => handleCheck(item.batchId)} />
                                                        </td>
                                                        <td className="px-6  whitespace-nowrap text-sm  text-gray-800 ">{item.batchId}</td>
                                                        <td className="px-6  whitespace-nowrap text-sm  text-gray-800 ">{item.orderType}</td>
                                                        <td className="px-6  whitespace-nowrap text-sm  text-gray-800 ">{item.action}</td>
                                                        <td className="px-6  whitespace-nowrap text-sm  text-gray-800 ">{item.clientCode}</td>
                                                        <td className="px-6  whitespace-nowrap text-sm  text-gray-800 ">{item.status}</td>
                                                    </tr>
                                                ))
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );

}

export default OrderManagement;