import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import { useSelector } from "react-redux";
import config from '../../config';
import { useNavigate } from "react-router-dom";
import { IoWarningOutline } from "react-icons/io5";

const AddCashTransaction = () => {
    const navigate = useNavigate();
    const companyId = useSelector((state) => state.user.companyId);

    const [baskets, setBaskets] = useState([])
    const [clients, setClients] = useState([])
    const [categories, setCategories] = useState([])
    const [transactions, setTransactions] = useState([])
    const [err, setErr] = useState("")

    const [inputs, setInputs] = useState({
        leadgerId: "",
        comment: "",
        clientName: "",
        category: "",
        clientCode: "",
        categoryId: "",
        updatedOn: "",
        clientId: "",
        companyId: "",
        basketName: "",
        basketId: "",
        amount: "",
        transactionType: "",
        transactionTypeId: "",
    })

    useEffect(() => {        
        getBaskets()
        getCategories()        
        getTransactionTypes()
    }, [])

    const getBaskets = async (e) => {
        try {
            const res = await axios.get(config.BL_API_URL + "/basket/v1/getBasketByCompanyId", { params: { companyId } });

            if (res.data)
            setBaskets(res.data) 
            else
                setErr("backend error")

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }

    const getCategories = async (e) => {
        try {
            const res = await axios.get(config.BL_API_URL+"/CashLedger/v1/getCashTransactionCategories");

            if (res.data)
            setCategories(res.data) 
            else
                setErr("backend error")

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }

    const getTransactionTypes = async (e) => {
        try {
            const res = await axios.get(config.BL_API_URL+"/CashLedger/v1/getTransactionTypes");

            if (res.data)
            setTransactions(res.data) 
            else
                setErr("backend error")

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }

    const getClients = async (e) => {
        try {
            const res = await axios.get(config.BL_API_URL+"/BasketClientMap/v1/getBasketSubscriber", {params: {basketId: e}});

            if (res.data)
            setClients(res.data) 
            else
                setErr("backend error")

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }

    const handleAddTransaction = async (e) => {
        e.preventDefault();
        inputs.companyId = companyId;
        try {
            const jsonString = JSON.stringify(inputs);
            
            const res = await axios.post(config.BL_API_URL+"/CashLedger/v1/addCashLedgerTransaction", inputs);

            if (res.status === 200)
            {
                navigate("/cashregister");
                //window.location.href = "/cashregister";
            }
            else
                setErr(res.data.message || "backend error");

        } catch (err) {
            //console.log("first")
            if (err.response)
                setErr(err.response.data);
            else
                setErr("Server error...");
        }
    }

    const handleChange = (e) => {
        if(e.target.name === "updatedOn" || e.target.name === "comment"){
            setInputs(prev => ({ ...prev, [e.target.name]: e.target.value,  [e.target.id]: e.target.value}))
        }
        else{
            setInputs(prev => ({ ...prev, [e.target.name]: e.target.value,  [e.target.id]: Number(e.target.value)}))
        }

        if(e.target.name === "basketName"){
            getClients(e.target.value)
        }
        if(e.target.name === "category"){
            categories.map((category) => {
                if(category.id == e.target.value){
                    const myElement = document.getElementById("transactionTypeId");
                    myElement.value = category.cashTransactionType.id;
                    setInputs(prev => ({ ...prev, transactionType: category.cashTransactionType.name, transactionTypeId: category.cashTransactionType.id}));
                }
            });
            
        }
            
    }

    return (
        <div>
            <div className="my-14 md:px-14 max-w-screen-2xl mx-auto ">
                <div className="max-w-4xl px-4 sm:px-6 lg:px-8 lg:py-2 mx-auto">
                    <div className="bg-formbackground rounded-xl shadow p-4 sm:p-7 ">
                        <div className="mb-8">
                            <h2 className="text-xl font-bold text-gray-800 ">
                                Add New Cash Transaction 
                            </h2>
                            <p><span><IoWarningOutline /></span> Warning!! Adding cash transaction of category Investment, additional investment
                            would generate buy orders and adding System Charges or any withdraw category would generate sell orders if there is no sufficient cash, so use carefully!</p>
                        </div>
                        <form>
                            <div className="grid sm:grid-cols-12 gap-2 sm:gap-6">

                                {/* Basket Name ---- */}
                                <div className="sm:col-span-2">
                                    <label htmlFor="basketName" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                        <span >Basket Name </span>
                                    </label>
                                </div>
                                <div className="sm:col-span-4">
                                    <div className="sm:flex">
                                    <select
                                        onChange={handleChange}
                                        name="basketName" id="basketId"
                                        className="py-2 px-3 pe-9 block min-w-full w-full sm:w-auto border-gray-200 shadow-sm -mt-px -ms-px rounded-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none ">
                                        <option value="-">- Select -</option>
                                        {baskets && (
                                            baskets.map((basket, index) => <option key={index} value={basket.basketId}>{basket.name}</option>)
                                        )}
                                    </select>
                                    </div>
                                </div>
                                {/* clients Name ---- */}
                                <div className="sm:col-span-2">
                                    <label htmlFor="clientName" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                        <span >Client Name</span>
                                    </label>
                                </div>
                                <div className="sm:col-span-4">
                                    <div className="sm:flex">
                                    <select
                                        onChange={handleChange}
                                        name="clientName" id="clientId"
                                        className="py-2 px-3 pe-9 block min-w-full w-full sm:w-auto border-gray-200 shadow-sm -mt-px -ms-px rounded-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none ">
                                        <option value="-">- Select -</option>
                                        {clients && (
                                            clients.map((client, index) => <option key={index} value={client.clientId}>{client.clientName}</option>)
                                        )}
                                    </select>
                                    </div>
                                </div>


                                {/* Category ---- */}
                                <div className="sm:col-span-2">
                                    <label htmlFor="category" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                        <span>Category</span>
                                    </label>
                                </div>
                                <div className="sm:col-span-4">
                                    <div className="sm:flex">
                                        <select
                                            onChange={handleChange}
                                            name="category" id="categoryId"
                                            className="py-2 px-3 pe-9 block min-w-full w-full sm:w-auto border-gray-200 shadow-sm -mt-px -ms-px rounded-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none ">
                                            <option value="-">- Select -</option>
                                            {categories && (
                                                categories.map((category, index) => <option key={index} value={category.id}>{category.name}</option>)
                                            )}
                                        </select>
                                    </div>
                                </div>
                                {/* date from ---- */}
                                <div className="sm:col-span-2">
                                    <label htmlFor="addedOn" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                        <span >Transaction Date</span>
                                    </label>
                                </div>
                                <div className="sm:col-span-4">
                                    <div className="sm:flex">
                                        <input
                                            onChange={handleChange}
                                            type="date" name="updatedOn" id="updatedOn" placeholder=""
                                            className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " />
                                    </div>
                                </div>


                                {/* Type ---- */}
                                <div className="sm:col-span-2">
                                    <label htmlFor="transactionType" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                        <span >Transaction Type</span>
                                    </label>
                                </div>
                                <div className="sm:col-span-4 min-w-full">

                                    <select disabled="true"
                                        onChange={handleChange}
                                        name="transactionType" id="transactionTypeId"
                                        className="py-2 px-3 pe-9 block min-w-full w-full sm:w-auto border-gray-200 shadow-sm -mt-px -ms-px rounded-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none ">
                                        <option value="-">- Select -</option>
                                        {transactions && (
                                            transactions.map((transaction, index) => <option key={index} value={transaction.id}>{transaction.name}</option>)
                                        )}
                                    </select>

                                </div>

                                {/* Amount ---- */}
                                <div className="sm:col-span-2">
                                    <label htmlFor="amount" className="inline-block text-sm text-gray-800 mt-2.5 ">
                                        <span > Amount</span>
                                    </label>
                                </div>
                                <div className="sm:col-span-4">
                                    <div className="sm:flex">
                                        <input
                                            onChange={handleChange}
                                            type="number" name="amount" id="amount" placeholder=""
                                            className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " />
                                    </div>
                                </div>



                                {/* comment ---- */}
                                <div className="sm:col-span-2">
                                    <label htmlFor="comment" className="inline-block text-sm text-gray-800 mt-2.5">
                                        <span >Comment</span>
                                    </label>
                                </div>
                                <div className="sm:col-span-10">
                                    <input
                                        onChange={handleChange}
                                        name="comment" id="comment"
                                        className="py-2 px-3 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none " ></input>
                                </div>

                                {/* Files ---- */}
                                <div className="sm:col-span-2">                                   
                                </div>                                
                            </div>


                            <div className="flex justify-end gap-x-2 mt-2">

                                <Link to="/cashregister" type="button" className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none ">
                                    Cancel
                                </Link>
                                <button type="button" onClick={handleAddTransaction} className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none ">
                                    Save changes
                                </button>
                            </div>
                            <p className="text-red-400 mt-8">
                                {err}
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default AddCashTransaction;